@font-face {
font-family: 'silka';
src: url(/_next/static/media/6b5a0739ad8ea534-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
}@font-face {font-family: 'silka Fallback';src: local("Arial");ascent-override: 63.30%;descent-override: 17.64%;line-gap-override: 0.00%;size-adjust: 148.95%
}.__className_f669c7 {font-family: 'silka', 'silka Fallback';font-weight: bold
}.__variable_f669c7 {--font-silka: 'silka', 'silka Fallback'
}

@font-face {
unicode-range: U+0000-00FF;
font-stretch: 75% 125%;
font-variation-settings: "opsz" 6;
font-family: 'adelphi';
src: url(/_next/static/media/a3cf7ce3fe5567aa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 125 950;
font-style: oblique 0deg 20deg;
}@font-face {font-family: 'adelphi Fallback';src: local("Arial");ascent-override: 101.72%;descent-override: 29.06%;line-gap-override: 0.00%;size-adjust: 99.10%
}.__className_43ddfa {font-family: 'adelphi', 'adelphi Fallback'
}.__variable_43ddfa {--font-adelphi: 'adelphi', 'adelphi Fallback'
}

